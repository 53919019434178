import store from "../state/store";

export default [
    {
        path: "/login",
        name: "login",
        component: () => import("../views/account/login.vue"),
        meta: {
            title: "Login",
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters["auth/loggedIn"]) {
                    // Redirect to the home page instead
                    next({name: "default"});
                } else {
                    // Continue to the login page
                    next();
                }
            },
        },
    },
    {
        path: "/register",
        name: "Register",
        component: () => import("../views/account/register.vue"),
        meta: {
            title: "Register",
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters["auth/loggedIn"]) {
                    // Redirect to the home page instead
                    next({name: "default"});
                } else {
                    // Continue to the login page
                    next();
                }
            },
        },
    },
    {
        path: "/forgot-password",
        name: "Forgot password",
        component: () => import("../views/account/forgot-password.vue"),
        meta: {
            title: "Forgot Password",
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters["auth/loggedIn"]) {
                    // Redirect to the home page instead
                    next({name: "default"});
                } else {
                    // Continue to the login page
                    next();
                }
            },
        },
    },
    {
        path: "/",
        name: "default",
        meta: {
            title: "Statistik Dashboard",
            authRequired: true,
        },
        component: () => import("../views/dashboard/analytics/index"),
    },
    {
        path: "/families/details",
        name: "family-details",
        meta: {title: "Familie Details", authRequired: true},
        component: () => import("../views/families/details"),
    },
    {
        path: "/families/all",
        name: "family-all-list",
        meta: {title: "Familie Liste", authRequired: true},
        component: () => import("../views/families/all"),
    },
    {
        path: "/families/new",
        name: "family-new-list",
        meta: {title: "Familie Liste", authRequired: true},
        component: () => import("../views/families/new"),
    },
    {
        path: "/families/registered",
        name: "family-registered-list",
        meta: {title: "Familie Liste", authRequired: true},
        component: () => import("../views/families/registered"),
    },
    {
        path: "/families/processed",
        name: "family-processed-list",
        meta: {title: "Familie Liste", authRequired: true},
        component: () => import("../views/families/processed"),
    },
    {
        path: "/helpers/details",
        name: "helper-details",
        meta: {title: "Helferinnen & Helfer Details", authRequired: true},
        component: () => import("../views/helpers/details"),
    },
    {
        path: "/helpers/all",
        name: "helpers-all-list",
        meta: {title: "Helferinnen & Helfer Liste", authRequired: true},
        component: () => import("../views/helpers/all"),
    },
    {
        path: "/helpers/not-assigned",
        name: "helpers-not-assigned-list",
        meta: {title: "Nicht zugewiesen", authRequired: true},
        component: () => import("../views/helpers/not-assigned"),
    },
    {
        path: "/helpers/diener",
        name: "helpers-diener-list",
        meta: {title: "Diener Liste", authRequired: true},
        component: () => import("../views/helpers/diener"),
    },
    {
        path: "/helpers/schmutzli",
        name: "helpers-schmutzli-list",
        meta: {title: "Schmutzli Liste", authRequired: true},
        component: () => import("../views/helpers/schmutzli"),
    },
    {
        path: "/helpers/oberschmutzli",
        name: "helpers-oberschmutzli-list",
        meta: {title: "Oberschmutzli Liste", authRequired: true},
        component: () => import("../views/helpers/oberschmutzli"),
    },
    {
        path: "/helpers/nikolaus",
        name: "helpers-nikolaus-list",
        meta: {title: "St. Nikolaus Liste", authRequired: true},
        component: () => import("../views/helpers/nikolaus"),
    },
    {
        path: "/teams/divide",
        name: "teams-divide",
        meta: {title: "Gruppen einteilen", authRequired: true},
        component: () => import("../views/teams/divide"),
    },
    {
        path: "/teams/1",
        name: "teams-view-1",
        meta: {title: "Gruppe 1", authRequired: true},
        component: () => import("../views/teams/1"),
    },
    {
        path: "/teams/2",
        name: "teams-view-2",
        meta: {title: "Gruppe 2", authRequired: true},
        component: () => import("../views/teams/2"),
    },
    {
        path: "/teams/3",
        name: "teams-view-3",
        meta: {title: "Gruppe 3", authRequired: true},
        component: () => import("../views/teams/3"),
    },
    {
        path: "/teams/4",
        name: "teams-view-4",
        meta: {title: "Gruppe 4", authRequired: true},
        component: () => import("../views/teams/4"),
    },
    {
        path: "/teams/5",
        name: "teams-view-5",
        meta: {title: "Gruppe 5", authRequired: true},
        component: () => import("../views/teams/5"),
    },
    {
        path: "/teams/6",
        name: "teams-view-6",
        meta: {title: "Gruppe 6", authRequired: true},
        component: () => import("../views/teams/6"),
    },
    {
        path: "/teams/7",
        name: "teams-view-7",
        meta: {title: "Gruppe 7", authRequired: true},
        component: () => import("../views/teams/7"),
    },
    {
        path: "/teams/8",
        name: "teams-view-8",
        meta: {title: "Gruppe 8", authRequired: true},
        component: () => import("../views/teams/8"),
    },
    {
        path: "/teams/9",
        name: "teams-view-9",
        meta: {title: "Gruppe 9", authRequired: true},
        component: () => import("../views/teams/9"),
    },
    {
        path: "/teams/10",
        name: "teams-view-10",
        meta: {title: "Gruppe 10", authRequired: true},
        component: () => import("../views/teams/10"),
    },
    {
        path: "/admin/all-users",
        name: "admin-all-users-list",
        meta: {title: "All Users", authRequired: true, role: "superuser"},
        component: () => import("../views/admin/all-users"),
    },
    {
        path: "/logout",
        name: "logout",
        meta: {
            title: "Logout",
            authRequired: false,
            beforeResolve(routeTo, routeFrom, next) {
                localStorage.clear();
                next();
            },
        },
        component: () => import("../views/auth/logout/basic"),
    },
    {
        path: "/auth/signin-basic",
        name: "signin-basic",
        meta: {
            title: "Signin Basic",
            authRequired: true,
        },
        component: () => import("../views/auth/signin/basic"),
    },
    {
        path: "/auth/signin-cover",
        name: "signin-cover",
        meta: {
            title: "Signin Cover",
            authRequired: true,
        },
        component: () => import("../views/auth/signin/cover"),
    },
    {
        path: "/auth/signup-basic",
        name: "signup-basic",
        meta: {
            title: "Signup Basic",
            authRequired: true,
        },
        component: () => import("../views/auth/signup/basic"),
    },
    {
        path: "/auth/signup-cover",
        name: "signup-cover",
        meta: {
            title: "Signup Cover",
            authRequired: true,
        },
        component: () => import("../views/auth/signup/cover"),
    },
    {
        path: "/auth/reset-pwd-basic",
        name: "reset-pwd-basic",
        meta: {
            title: "Reset Password",
            authRequired: true,
        },
        component: () => import("../views/auth/reset/basic"),
    },
    {
        path: "/auth/reset-pwd-cover",
        name: "reset-pwd-cover",
        meta: {
            title: "Reset Password",
            authRequired: true,
        },
        component: () => import("../views/auth/reset/cover"),
    },
    {
        path: "/auth/create-pwd-basic",
        name: "create-pwd-basic",
        meta: {
            title: "create Password",
            authRequired: true,
        },
        component: () => import("../views/auth/create/basic"),
    },
    {
        path: "/auth/create-pwd-cover",
        name: "create-pwd-cover",
        meta: {
            title: "create Password",
            authRequired: true,
        },
        component: () => import("../views/auth/create/cover"),
    },
    {
        path: "/auth/lockscreen-basic",
        name: "lock-screen-basic",
        meta: {
            title: "Lock Screen",
            authRequired: true,
        },
        component: () => import("../views/auth/lockscreen/basic"),
    },
    {
        path: "/auth/lockscreen-cover",
        name: "lock-screen-cover",
        meta: {
            title: "Lock Screen",
            authRequired: true,
        },
        component: () => import("../views/auth/lockscreen/cover"),
    },
    {
        path: "/auth/twostep-basic",
        name: "twostep-basic",
        meta: {
            title: "Two Step Auth",
            authRequired: true,
        },
        component: () => import("../views/auth/twostep/basic"),
    },
    {
        path: "/auth/twostep-cover",
        name: "twostep-cover",
        meta: {
            title: "Two Step Auth",
            authRequired: true,
        },
        component: () => import("../views/auth/twostep/cover"),
    },
    {
        path: "/auth/404",
        name: "404",
        meta: {
            title: "Error 404",
            authRequired: true,
        },
        component: () => import("../views/auth/errors/404"),
    },
    {
        path: "/auth/500",
        name: "500",
        meta: {
            title: "Error 500",
            authRequired: true,
        },
        component: () => import("../views/auth/errors/500"),
    },
    {
        path: "/auth/404-basic",
        name: "404-basic",
        meta: {
            title: "Error 404",
            authRequired: true,
        },
        component: () => import("../views/auth/errors/404-basic"),
    },
    {
        path: "/auth/404-cover",
        name: "404-cover",
        meta: {
            title: "Error 404",
            authRequired: true,
        },
        component: () => import("../views/auth/errors/404-cover"),
    },
    {
        path: "/auth/ofline",
        name: "ofline",
        meta: {
            title: "Oflone",
            authRequired: true,
        },
        component: () => import("../views/auth/errors/ofline"),
    },
    {
        path: "/auth/logout-basic",
        name: "logout-basic",
        meta: {
            title: "Logout",
            authRequired: true,
        },
        component: () => import("../views/auth/logout/basic"),
    },
    {
        path: "/auth/logout-cover",
        name: "logout-cover",
        meta: {
            title: "Logout",
            authRequired: true,
        },
        component: () => import("../views/auth/logout/cover"),
    },
    {
        path: "/auth/success-msg-basic",
        name: "success-msg-basic",
        meta: {
            title: "Success Message",
            authRequired: true,
        },
        component: () => import("../views/auth/success-msg/basic"),
    },
    {
        path: "/auth/success-msg-cover",
        name: "success-msg-cover",
        meta: {
            title: "Success Message",
            authRequired: true,
        },
        component: () => import("../views/auth/success-msg/cover"),
    }
];
