import {initializeApp} from "firebase/app";
import {
    addDoc,
    collection,
    deleteDoc,
    doc,
    getFirestore,
    updateDoc,
} from "firebase/firestore";
import {
    getAuth,
    signInWithEmailAndPassword
} from "firebase/auth";
import {getFunctions} from 'firebase/functions';
import {
    getDownloadURL,
    getStorage,
    ref,
    uploadBytes,
} from "firebase/storage";

const firebaseConfig = {
    apiKey: process.env.VUE_APP_APIKEY,
    authDomain: process.env.VUE_APP_AUTHDOMAIN,
    projectId: process.env.VUE_APP_PROJECTId,
    storageBucket: process.env.VUE_APP_STORAGEBUCKET,
    messagingSenderId: process.env.VUE_APP_MESSAGINGSENDERID,
    appId: process.env.VUE_APP_APPId,
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);
const functions = getFunctions(app, "europe-west6");

async function uploadQrCodeImage(file) {
    const storageRef = ref(storage, 'qrcode.png');

    await uploadBytes(storageRef, file);
}

async function downloadQrCodeImage() {
    return await getDownloadURL(ref(storage, 'qrcode.png'));
}

async function deleteFamilyFromFirestore(uid) {
    await deleteDoc(doc(db, "families", uid));
}

async function addFamilyToFirestore(family) {
    const newFamilyDocRef = doc(collection(db, "families"));
    family.uid = newFamilyDocRef.id;

    await addDoc(collection(db, "families"), family);
}

async function updateFamilyToFirestore(family) {
    await updateDoc(doc(db, "families", family.uid), family);
}

async function updateHelperToFirestore(helper) {
    await updateDoc(doc(db, "helpers", helper.uid), helper);
}

async function deleteHelperFromFirestore(uid) {
    await deleteDoc(doc(db, "helpers", uid));
}

async function addHelperToFirestore(helper) {
    const newHelperDocRef = doc(collection(db, "helpers"));
    helper.uid = newHelperDocRef.id;

    await addDoc(collection(db, "helpers"), helper);
}

function loginUser(email, password) {
    return signInWithEmailAndPassword(auth, email, password);
}

export {
    app,
    auth,
    db,
    storage,
    functions,
    deleteFamilyFromFirestore,
    addFamilyToFirestore,
    updateFamilyToFirestore,
    updateHelperToFirestore,
    loginUser,
    addHelperToFirestore,
    deleteHelperFromFirestore,
    uploadQrCodeImage,
    downloadQrCodeImage
}
