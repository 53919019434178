import {auth} from "../../firebase/firebase";
import {
    createUserWithEmailAndPassword,
    sendPasswordResetEmail,
    signOut
} from "firebase/auth";
import {
    doc,
    getDoc
} from "firebase/firestore";
import {db} from "@/firebase/firebase";

export const state = {
    currentUser: null,
    profile: null,
    authIsReady: false,
    role: "user"
};

export const getters = {
    // Whether the user is currently logged in.
    loggedIn(state) {
        return state.currentUser != null;
    },
    role(state) {
        return state.role;
    }
};

export const mutations = {
    SET_CURRENT_USER(state, payload) {
        if (payload != null) {
            const {user, claims} = payload;
            state.currentUser = user;
            if (claims != null && claims.role) {
                state.role = claims.role;
            }
            return;
        }

        state.currentUser = null;
    },
    SET_PROFILE(state, payload) {
        if (payload != null) {
            const {profile} = payload;
            state.profile = profile;
        }
    },
    SET_AUTH_IS_READY(state, payload) {
        state.authIsReady = payload;
    },
};

export const actions = {
    // Logs in the current user.
    async logIn({commit}, {user} = {}) {
        const idTokenResult = user ? (await user.getIdTokenResult(true)) : null;

        commit("SET_CURRENT_USER", {user: user, claims: idTokenResult.claims});
        const docRef = doc(db, "users", user.uid);
        const docSnap = await getDoc(docRef);
        commit("SET_PROFILE", {profile: docSnap.data()});
    },

    // Logs out the current user.
    logOut({commit}) {
        signOut(auth);
        commit("SET_CURRENT_USER", null);
    },

    // register the user
    register({commit}, {username, email, password} = {}) {
        const res = createUserWithEmailAndPassword(auth, email, password);
        if (res) {
            commit("SET_CURRENT_USER", {user: res.user});
        } else {
            throw "Could not register";
        }
    },

    // register the user
    // eslint-disable-next-line no-unused-vars
    resetPassword(context, {email} = {}) {
        sendPasswordResetEmail(auth, email, {
            url: window.location.protocol + "//" + window.location.host + "/login",
        });
    },
};
