import {createApp} from "vue";
import App from "./App.vue";
import router from "./router";
import AOS from "aos";
import "aos/dist/aos.css";
import i18n from "./i18n";
import store from "./state/store";
import {auth} from "./firebase/firebase";
import {onAuthStateChanged} from "firebase/auth";
import BootstrapVue3 from "bootstrap-vue-3";
import vClickOutside from "click-outside-vue3";
import VueApexCharts from "vue3-apexcharts";
import Maska from "maska";
import VueFeather from "vue-feather";
import Particles from "particles.vue3";
import VueGoogleMaps from "@fawmi/vue-google-maps";
import "@/assets/scss/config/material/app.scss";
import "@vueform/slider/themes/default.css";

AOS.init({
    easing: "ease-out-back",
    duration: 1000,
});
const app = createApp(App)
.use(store)
.use(VueApexCharts)
.use(BootstrapVue3)
.component(VueFeather.type, VueFeather)
.use(Maska)
.use(Particles)
.use(i18n)
.use(vClickOutside)
.use(VueGoogleMaps, {
    load: {
        key: process.env.VUE_APP_MAPSKEY,
    },
});

const unsub = onAuthStateChanged(auth, async (user) => {
    const idTokenResult = user ? (await user.getIdTokenResult(true)) : null;
    const signInSecondFactor = idTokenResult ? idTokenResult.signInSecondFactor : null;
    const claims = idTokenResult ? idTokenResult.claims : null;
    await store.commit("auth/SET_AUTH_IS_READY", true);
    if (signInSecondFactor != null) {
        await store.dispatch("auth/logIn", {user});
        await store.commit("auth/SET_CURRENT_USER", {user, claims});
    }
    app.use(router);
    app.mount("#app");
    unsub();

});
